<template>
  <div class="content">
    <div>
      <el-row :gutter="20">
<!--        <el-col v-if="list.accountOutline !=null" :xs="24" :md="12" :lg="6">-->
<!--          <el-card class="box-card">-->
<!--            <div slot="header" class="clearfix">-->
<!--              <span class="header-title">账号概况</span>-->
<!--&lt;!&ndash;              <el-button type="primary" @click="getWithdrawCash" style="float: right; padding: 5px">提现</el-button>&ndash;&gt;-->
<!--            </div>-->
<!--            <div style="display: flex;align-content: center;justify-content: space-between;">-->
<!--              <div class="header-content">-->
<!--                <div class="num">{{list.accountOutline.incomeCount == undefined ? 0: list.accountOutline.incomeCount}}</div>-->
<!--                <div class="description">总收益</div>-->
<!--              </div>-->
<!--              <div class="header-content">-->
<!--                <div class="num">{{list.accountOutline.withdrawn == undefined ? 0: list.accountOutline.withdrawn}}</div>-->
<!--                <div class="description">已提现</div>-->
<!--              </div>-->
<!--              <div class="header-content">-->
<!--                <div class="num">{{list.accountOutline.withdrawable == undefined ? 0: list.accountOutline.withdrawable}}</div>-->
<!--                <div class="description">可提现</div>-->
<!--              </div>-->
<!--            </div>-->
<!--          </el-card>-->
<!--        </el-col>-->
        <el-col :xs="32" :md="12" :lg="8">
          <el-card class="box-card">
            <div slot="header" class="clearfix">
              <span class="header-title">今日业绩</span>
            </div>
            <div style="display: flex;align-content: center;justify-content: space-between;">
              <div class="header-content">
                <div class="num">{{list.todayPerformance.rechargeAmount == undefined ? 0 : list.todayPerformance.rechargeAmount}}</div>
                <div class="description">充值金额</div>
              </div>
              <div class="header-content">
                <div class="num">{{list.todayPerformance.rechargeCount == undefined ? 0 : list.todayPerformance.rechargeCount}}</div>
                <div class="description">充值人数</div>
              </div>
            </div>
          </el-card>
        </el-col>
        <el-col :xs="24" :md="12" :lg="8">
          <el-card class="box-card">
            <div slot="header" class="clearfix">
              <span class="header-title">今日推广</span>
            </div>
            <div style="display: flex;align-content: center;justify-content: space-between;">
              <div class="header-content">
                <div class="num">{{list.todayPromotion.gameRoleCount == undefined ? 0 : list.todayPromotion.gameRoleCount}}</div>
                <div class="description">角色创建数</div>
              </div>
              <div class="header-content">
                <div class="num">{{list.todayPromotion.ipDeviceCount == undefined ? 0 : list.todayPromotion.ipDeviceCount}}</div>
                <div class="description">设备数</div>
              </div>
            </div>
          </el-card>
        </el-col>
        <el-col :xs="24" :md="12" :lg="8">

          <el-card class="box-card">
            <div slot="header" class="clearfix">
              <span class="header-title">本月累计业绩</span>
            </div>
            <div style="display: flex;align-content: center;justify-content: space-between;">
              <div class="header-content">
                <div class="num">{{list.monthPerform.rechargeAmount == undefined ? 0 : list.monthPerform.rechargeAmount}}</div>
                <div class="description">本月累计充值</div>
              </div>
              <div class="header-content">
                <div class="num">{{list.monthPerform.rechargeCount == undefined ? 0 : list.monthPerform.rechargeCount}}</div>
                <div class="description">累计充值人数</div>
              </div>
            </div>
          </el-card>
        </el-col>
      </el-row>
    </div>
<!--    <withdraw-cash-dialog :dialogVisible="dialogVisible"></withdraw-cash-dialog>-->
  </div>
</template>

<script>
import withdrawCashDialog from "../settlementManagement/components/withdrawCashDialog";
import {overviewOverviewInfo} from "../../service/moudules/promotionLinkApi";
import cookieUtils from '@/util/cookieUtils'
export default {
  name: "overviewStatistics",
  components: {
    withdrawCashDialog
  },
  data () {
    return {
      dialogVisible: true,
      list: {
        accountOutline: {}, //账号概况
        todayPerformance: {}, //今日业绩
        todayPromotion: {}, //今日推广
        monthPerform: {}, //本月累计业绩
      }
    }
  },
  mounted() {
    this.getList()
  },
  methods: {
    getWithdrawCash() {

    },
    async getList() {
      let sessionId = cookieUtils.getCookie("sessionId");
      let params = {
        sessionId: sessionId
      }
      let res = await overviewOverviewInfo(params);
      this.list = {
        accountOutline: res.data.accountOutline, //账号概况
        todayPerformance: res.data.todayPerformance == null ? {} : res.data.todayPerformance, //今日业绩
        todayPromotion: res.data.todayPromotion == null ? {} : res.data.todayPromotion, //今日推广
        monthPerform: res.data.monthPerform == null ? {} : res.data.monthPerform, //本月累计业绩
      }
      console.log("res:",res,this.list);
    }
  }
}
</script>

<style scoped lang="less">
.content {
  padding: 20px 10px;
  margin: 10px;
  //border: 1px solid #dcdfe6;
  text-align: initial;
  background-color: white;
  .box-card{
    min-height: 100px;
    margin-bottom: 20px;
  }
  .header-title{
    position: relative;
    font-weight: 600;
    display: inline-block;
    padding-left: 8px;
  }
  .header-title::before {
    position: absolute;
    content: '';
    width: 4px;
    left: -6px;
    height: 22px;
    background-color: #ff8143;
    top: 0px;
    border-radius: 10px;
  }
  .header-content{
    text-align: center;
    .num{
      font-size: 20px;
      padding-bottom: 5px;
      color: #ff8143;
    }
    .description{
      font-size: 14px;
    }
  }
  ::v-deep .el-card__header{
    padding: 12px 20px;
  }
}

</style>